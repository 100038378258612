@use "../../styles/partials/mixins" as *;

.app {
  height: 100vh;
  width: 100%;
  position: relative;
  // background-image: url("../../assets/background/stacked-steps-haikei.svg");
  // background-repeat: no-repeat;
  // width: 100%;
  // height: 100%;
}

.app-content {
  position: relative;
  z-index: 1; /* Ensure that child components are rendered above the background */
}

.rectangle {
  width: 100%;
  height: max-content;
  position: absolute;
  z-index: 1;
  @include glass-effect;
  backdrop-filter: blur(5px);
  /* fallback for old browsers */
  -webkit-backdrop-filter: blur(0.3125rem);
  border: none;
}

.circle {
  &-1 {
    position: absolute;
    z-index: 1;
    top: 20%;
    left: 20%;
    background: linear-gradient(to right, #adadad, #fff);
    height: 350px;
    width: 350px;
    border-radius: 67% 33% 38% 62% / 58% 30% 70% 42%;
    filter: blur(8rem);
  }

  &-2 {
    position: absolute;
    top: 45%;
    right: 25%;
    z-index: 1;
    background: linear-gradient(to right, #f80759, #bc4e9c);
    height: 40rem;
    width: 40rem;
    border-radius: 53% 47% 75% 25% / 48% 30% 70% 52%;
    filter: blur(6rem);
  }

  &-3 {
    position: absolute;
    z-index: 1;
    top: 110%;
    right: 0%;
    background: linear-gradient(to right, #ff5e62, #ff9966);
    height: 25rem;
    width: 20rem;
    border-radius: 50%;
    filter: blur(8rem);

    @include sm {
      top: 75%;
      right: 0%;
    }
  }

  &-4 {
    position: absolute;
    z-index: 1;
    top: 60%;
    right: 99%;
    background: linear-gradient(to right, #69ff5e, #66ff66);
    height: 45rem;
    width: 25rem;
    border-radius: 31% 69% 15% 85% / 58% 45% 55% 42%;
    filter: blur(8rem);

    @include sm {
      top: 45%;
      right: 99%;
    }
  }

  &-5 {
    position: absolute;
    z-index: 1;
    top: -15%;
    right: 0%;
    background: linear-gradient(to right, #8e54e9, #4776e6);
    height: 20rem;
    width: 20rem;
    border-radius: 50%;
    filter: blur(8rem);
  }

  &-6 {
    position: absolute;
    z-index: 1;
    top: 0%;
    left: 1%;
    background: linear-gradient(to right, #f627e8, #fff);
    height: 9rem;
    width: 9rem;
    border-radius: 67% 33% 38% 62% / 58% 30% 70% 42%;
    filter: blur(6rem);
  }
}

.circle {
  &-1 {
    animation: bounce 8s linear infinite;
  }
  &-2 {
    animation: bounce 8s linear infinite 1s;
  }
  &-3 {
    animation: bounce 8s linear infinite 2s;
  }
  &-4 {
    animation: bounce 8s linear infinite 3s;
  }
  &-5 {
    animation: bounce 8s linear infinite 4s;
  }
  &-6 {
    animation: bounce 8s linear infinite 5s;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0rem);
  }

  25% {
    transform: translateY(5rem);
  }

  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(5rem);
  }

  100% {
    transform: translateY(0px);
  }
}
