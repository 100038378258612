/* Media queries */

$mobile-breakpoint: 320px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;

@mixin sm {
  @media (min-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin md {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin lg {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin glass-effect {
  background: rgba(39, 39, 39, 0.1);
  backdrop-filter: blur(60px);
  /* fallback for old browsers */
  -webkit-backdrop-filter: blur(60px);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.3);
}
