@use "../../styles/partials/mixins" as *;

.firebaseui-container {
  @include sm {
    width: 1000rem;
    max-width: 25rem;
  }

  @include md {
    max-width: 40rem;
  }
}

.mdl-button--raised.mdl-button--colored {
  background: #ffba7a;
  color: #000;
  box-shadow: none;
  border-radius: 0.5rem;
}

.mdl-button--raised.mdl-button--colored:hover {
  background: #fc9b42;
  color: #000;
  box-shadow: none;
  border-radius: 0.5rem;
}

.mdl-textfield__input:active {
  border-bottom: 1px solid #ffba7a;
  border-radius: 0;
  color: #000;
}



form {
  // background-color: white;
  // border-radius: 0.5rem;
  // box-shadow: 0.2rem 0.2rem 2rem slategray;

  @include md {
    padding: 2rem;
  }

  @include lg {
    padding: 4rem;
  }
}
