@use "../../styles/partials/mixins" as *;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  &-content {
    background-color: #fff;
    padding: 1.25rem;
    border-radius: 0.3125rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 40%;

    @include sm {
      width: 80%;
    }
  }

  &-close {
    font-size: 2rem;
  }

  &-form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
      font-size: 20px;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    &__button {
      font-weight: bold;
    }

    &__input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: small;
    }
  }
}
