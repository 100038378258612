@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  position: relative;
  z-index: 0;
  margin: 0;
  padding: 0;
  background: radial-gradient(50% 50% at 50% 50%, #2E3040 5.21%, #18181F 82.81%);

}

/* custom scrollbar stylings for lists section and list components */

.lists-section::-webkit-scrollbar {
  height: .25rem;
}

.lists-section::-webkit-scrollbar-track {
  background-color: theme('colors.smoke');
}

.lists-section::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: theme('colors.ghost');
}

.list::-webkit-scrollbar {
  width: .25rem;
}

.list::-webkit-scrollbar-track {
  background-color: theme('colors.smoke');
}

.list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: theme('colors.ghost');
}