@use "../../styles/partials/mixins" as *;

.notes-box {
  @include glass-effect;
  background: linear-gradient(
    108deg,
    rgba(255, 255, 255, 0.46) -16.26%,
    rgba(255, 255, 255, 0) 100.97%
  );
  border: none;
}

.markdown-preview h1 {
  font-size: 24px;
}

.markdown-preview h2 {
  font-size: 20px;
}

.markdown-preview h3 {
  font-size: 18px;
}

.markdown-preview h4 {
  font-size: 16px;
}

.markdown-preview h5 {
  font-size: 14px;
}

.markdown-preview h6 {
  font-size: 12px;
}
